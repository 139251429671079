import React from "react"
import HeaderCategory from "../components/header"
import Footer from "../components/footer"
import Printdirectory from "../components/printdirectory"
import SeO from "../components/SEO"
export default function PrintDirectory() {
  return (
    <div>
      <SeO title={`PrintDirectory`} defer={true} />
      <HeaderCategory />
      <Printdirectory />
      <Footer />
    </div>
  )
}